<template>
  <div>
    <v-container fluid>
      <v-card class="ma-3 pa-3">
        <v-card-title primary-title>
          <div class="headline primary--text">Edit User Profile</div>
        </v-card-title>
      </v-card>
      <v-card class="ma-3 pa-3">
        <v-card-title primary-title>
          <div class="headline primary--text">User Details</div>
        </v-card-title>
        <v-card-text>
          <v-form ref="userchange" data-vv-scope="userchange">
            <v-text-field
              label="First Name"
              disabled
              v-model="firstName"
            ></v-text-field>
            <v-text-field
              label="Last Name"
              disabled
              v-model="lastName"
            ></v-text-field>
            <v-text-field
              label="E-mail"
              disabled
              type="email"
              v-model="email"
            ></v-text-field>

            <v-text-field
              label="Affiliation"
              disabled
              v-model="affiliation"
            ></v-text-field>
          </v-form>
        </v-card-text>
      </v-card>

      <v-card class="ma-3 pa-3">
        <v-card-title primary-title>
          <div class="headline primary--text">Multi-Factor Authentication</div>
        </v-card-title>
        <RegisterMFA />
      </v-card>

      <v-card class="ma-3 pa-3">
        <v-card-title primary-title>
          <div class="headline primary--text">Pipeline Options</div>
        </v-card-title>
        <v-card-text>
          <v-form data-vv-scope="pipeline" class="mt-3">
            <div class="subheading">Pipeline Version</div>
            <div class="subheading grey--text">
              You can read more about BugSeq versioning
              <a
                href="https://docs.bugseq.com/change-log/pipeline/"
                target="_blank"
                >here</a
              >. Note that selecting "Latest" will not pin your account to a
              specific version, but rather always run with the latest and
              greatest BugSeq features. This is the option we recommend for most
              users.
            </div>
            <v-combobox
              v-model="pipelineVersion"
              :items="pipelineVersions"
              label="Select a specific pipeline version"
              class="mt-3"
            ></v-combobox>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="submit_pipeline_options"
            :disabled="errors.any('pipeline')"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { components } from '@bugseq-site/app/src/lib/api/api'
import { readUserProfile } from "@bugseq-site/app/src/store/modules/api/getters";
import { dispatchUpdateUserProfile } from "@bugseq-site/app/src/store/modules/api/actions";
import RegisterMFA from "@bugseq-site/app/src/components/app/RegisterMFA.vue";

@Component({
  components: { RegisterMFA },
})
export default class UserProfileEdit extends Vue {
  public valid = true;
  public firstName: string = "";
  public lastName: string = "";
  public email: string = "";
  public affiliation: string = "";
  public pipelineVersion: string = "";

  public pipelineVersionLatest = "Latest";
  public pipelineVersions = [this.pipelineVersionLatest, "v5.6"];

  public created() {
    const userProfile = readUserProfile(this.$store);
    if (userProfile) {
      this.firstName = userProfile.first_name;
      this.lastName = userProfile.first_name;
      this.email = userProfile.email;
      this.affiliation = userProfile.affiliation;
      this.pipelineVersion =
        userProfile.pipeline_version || this.pipelineVersionLatest;
    }
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  public reset() {
    const userProfile = readUserProfile(this.$store);
    if (userProfile) {
      this.firstName = userProfile.first_name;
      this.lastName = userProfile.last_name;
      this.email = userProfile.email;
      this.affiliation = userProfile.affiliation;
      this.pipelineVersion =
        userProfile.pipeline_version || this.pipelineVersionLatest;
    }
  }

  public async submit_pipeline_options() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: components["schemas"]["UserUpdateRequest"] = {};
      if (this.pipelineVersion) {
        updatedProfile.pipeline_version = this.pipelineVersion;
      }
      await dispatchUpdateUserProfile(this.$store, updatedProfile);
    }
  }
}
</script>
